<template>
  <canvas id="cash-flow"></canvas>
</template>

<script>
import { Chart, registerables } from "chart.js";
import moment from "moment";
import "moment/locale/id";
Chart.register(...registerables);
export default {
  name: "ChartCashFlow",
  components: {},
  props: {
    data: Object,
  },
  data() {
    return {
      cashFlowChart: {
        chartType: "line",
        chartData: {
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "Mei",
            "Jun",
            "Jul",
            "Agu",
            "Sep",
            "Okt",
            "Nov",
            "Des",
          ],
          datasets: [
            {
              label: "Pemasukan",
              data: [12, 19, 10, 17, 12, 10, 7, 12, 19, 10, 17, 12],
              backgroundColor: "rgba(185, 88, 165, 0.14)",
              borderColor: "rgba(145, 69, 182, 1)",
              lineTension: 0.3,
              pointBackgroundColor: "rgba(145, 69, 182, 1)",
              fill: true,
            },
            {
              label: "Pengeluaran",
              data: [10, 25, 20, 25, 17, 20, 9, 10, 25, 20, 25, 17],
              backgroundColor: "rgba(110, 192, 254, 0.14)",
              borderColor: "rgba(110, 192, 254, 1)",
              lineTension: 0.3,
              pointBackgroundColor: "rgba(110, 192, 254, 1)",
              fill: true,
            },
          ],
        },
      },
      canvasChart: "",
    };
  },

  mounted() {
    // this.chartAsset();
  },

  methods: {
    chartAsset() {
      const chartElement = document.getElementById("cash-flow");
      // const gradientBg = chartElement.createLinierGradient(0,0,0,400)
      // gradientBg.addColorStop(0, 'rgba(145, 69, 182, 0.14)')
      //  gradientBg.addColorStop(, 'rgba(145, 69, 182, 0.14)')
      this.canvasChart = new Chart(chartElement, {
        type: this.cashFlowChart.chartType,
        data: this.cashFlowChart.chartData,
      });
    },
  },

  watch: {
    data: function (newVal) {
      var label = [];
      for (const key in newVal.label) {
        if (Object.hasOwnProperty.call(newVal.label, key)) {
          const element = newVal.label[key];
          label.push(moment(element + "-01").format("MMM YYYY"));
        }
      }
      this.cashFlowChart.chartData.labels = label;
      this.cashFlowChart.chartData.datasets[0].data = newVal.pemasukan;
      this.cashFlowChart.chartData.datasets[1].data = newVal.pengeluaran;
      if (this.canvasChart) {
        this.canvasChart.destroy();
      }
      this.chartAsset();
    },
  },
};
</script>
