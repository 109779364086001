<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>

    <div class="main-content">
      <section class="section">
        <div class="header">
          <div class="row">
            <div class="col-8 col-sm-9">
              <h4 class="title">Dashboard</h4>
            </div>
            <div class="col-4 col-sm-3">
              <Select2
                v-model="dataYear"
                :options="optionYear"
                placeholder="Pilih Tahun"
                :settings="{ minimumResultsForSearch: -1 }"
                @change="selectYear($event)"
                @select="selectYear($event)"
              ></Select2>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="row">
            <div class="col-4" style="margin-bottom: 32px">
              <div class="card card-activity">
                <div class="card-body">
                  <div class="card-title">Kegiatan Mendatang</div>
                  <div
                    v-for="(value, index) in dataDashboard.kegiatan.bulan"
                    :key="index"
                    class="mb-2"
                  >
                    <div class="card-text">{{ value }}</div>
                    <div class="line"></div>
                    <div class="card-content mt-4">
                      <div
                        class="d-flex align-items-center mb-3"
                        v-for="(val, key) in dataDashboard.kegiatan.nilai[
                          value
                        ]"
                        :key="key"
                      >
                        <div class="activity-date">
                          <div class="date">{{ convertDate(val.tanggal) }}</div>
                          <div class="day">
                            {{ convertDate(val.tanggal, "ddd") }}
                          </div>
                        </div>
                        <div class="activity-content">
                          {{ val.project }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="text-center mt-3"
                    v-if="dataDashboard.kegiatan.bulan == 0"
                  >
                    <p><i>Tidak ada kegiatan terdekat</i></p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-8" style="padding-right: 40px">
              <div class="d-flex flex-wrap justify-content-between">
                <div
                  class="card card-total-amount align-self-start gradation-1"
                >
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div class="card-title">Piutang Usaha</div>
                    <div>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.1665 14.1666H10.8332V13.3333H11.6665C12.1248 13.3333 12.4998 12.9583 12.4998 12.5V9.99998C12.4998 9.54164 12.1248 9.16664 11.6665 9.16664H9.1665V8.33331H12.4998V6.66665H10.8332V5.83331H9.1665V6.66665H8.33317C7.87484 6.66665 7.49984 7.04165 7.49984 7.49998V9.99998C7.49984 10.4583 7.87484 10.8333 8.33317 10.8333H10.8332V11.6666H7.49984V13.3333H9.1665V14.1666ZM16.6665 3.33331H3.33317C2.40817 3.33331 1.67484 4.07498 1.67484 4.99998L1.6665 15C1.6665 15.925 2.40817 16.6666 3.33317 16.6666H16.6665C17.5915 16.6666 18.3332 15.925 18.3332 15V4.99998C18.3332 4.07498 17.5915 3.33331 16.6665 3.33331ZM16.6665 15H3.33317V4.99998H16.6665V15Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                  <div class="total-amount">
                    Rp. {{ formatMoney(dataDashboard.piutang) }}
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center mt-2"
                  >
                    <div class="date">{{ today }}</div>
                    <div class="icon-more" @click="toPiutang">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.83309 9.16666H12.1581L9.40809 6.42499C9.25117 6.26807 9.16302 6.05524 9.16302 5.83333C9.16302 5.61141 9.25117 5.39858 9.40809 5.24166C9.56501 5.08474 9.77784 4.99658 9.99976 4.99658C10.2217 4.99658 10.4345 5.08474 10.5914 5.24166L14.7581 9.40833C14.834 9.48758 14.8934 9.58103 14.9331 9.68333C15.0164 9.88621 15.0164 10.1138 14.9331 10.3167C14.8934 10.419 14.834 10.5124 14.7581 10.5917L10.5914 14.7583C10.514 14.8364 10.4218 14.8984 10.3202 14.9407C10.2187 14.983 10.1098 15.0048 9.99976 15.0048C9.88975 15.0048 9.78083 14.983 9.67928 14.9407C9.57773 14.8984 9.48556 14.8364 9.40809 14.7583C9.32999 14.6809 9.26799 14.5887 9.22568 14.4871C9.18338 14.3856 9.1616 14.2767 9.1616 14.1667C9.1616 14.0566 9.18338 13.9477 9.22568 13.8462C9.26799 13.7446 9.32999 13.6525 9.40809 13.575L12.1581 10.8333H5.83309C5.61208 10.8333 5.40012 10.7455 5.24384 10.5892C5.08756 10.433 4.99976 10.221 4.99976 9.99999C4.99976 9.77898 5.08756 9.56702 5.24384 9.41074C5.40012 9.25446 5.61208 9.16666 5.83309 9.16666Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                </div>

                <div
                  class="card card-total-amount align-self-start gradation-2"
                >
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div class="card-title">Hutang Usaha</div>
                    <div>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="5"
                          y="3.33331"
                          width="10.8333"
                          height="14.1667"
                          rx="2"
                          stroke="white"
                          stroke-width="2"
                        />
                        <path
                          d="M12.5 8.33331V6.66665"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                        <path
                          d="M3.3335 7.5H6.66683"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                        <path
                          d="M3.3335 10.8333H6.66683"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                        <path
                          d="M3.3335 14.1667H6.66683"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                      </svg>
                    </div>
                  </div>
                  <div class="total-amount">
                    Rp. {{ formatMoney(dataDashboard.hutang) }}
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center mt-2"
                  >
                    <div class="date">{{ today }}</div>
                    <div class="icon-more" @click="toHutang">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.83309 9.16666H12.1581L9.40809 6.42499C9.25117 6.26807 9.16302 6.05524 9.16302 5.83333C9.16302 5.61141 9.25117 5.39858 9.40809 5.24166C9.56501 5.08474 9.77784 4.99658 9.99976 4.99658C10.2217 4.99658 10.4345 5.08474 10.5914 5.24166L14.7581 9.40833C14.834 9.48758 14.8934 9.58103 14.9331 9.68333C15.0164 9.88621 15.0164 10.1138 14.9331 10.3167C14.8934 10.419 14.834 10.5124 14.7581 10.5917L10.5914 14.7583C10.514 14.8364 10.4218 14.8984 10.3202 14.9407C10.2187 14.983 10.1098 15.0048 9.99976 15.0048C9.88975 15.0048 9.78083 14.983 9.67928 14.9407C9.57773 14.8984 9.48556 14.8364 9.40809 14.7583C9.32999 14.6809 9.26799 14.5887 9.22568 14.4871C9.18338 14.3856 9.1616 14.2767 9.1616 14.1667C9.1616 14.0566 9.18338 13.9477 9.22568 13.8462C9.26799 13.7446 9.32999 13.6525 9.40809 13.575L12.1581 10.8333H5.83309C5.61208 10.8333 5.40012 10.7455 5.24384 10.5892C5.08756 10.433 4.99976 10.221 4.99976 9.99999C4.99976 9.77898 5.08756 9.56702 5.24384 9.41074C5.40012 9.25446 5.61208 9.16666 5.83309 9.16666Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                </div>

                <div
                  class="card card-total-amount align-self-start gradation-3"
                >
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div class="card-title">Ketersediaan Kas</div>
                    <div>
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.0002 1.66669H5.00016C4.0835 1.66669 3.3335 2.41669 3.3335 3.33335V16.6667C3.3335 17.5834 4.0835 18.3334 5.00016 18.3334H15.0002C15.9168 18.3334 16.6668 17.5834 16.6668 16.6667V3.33335C16.6668 2.41669 15.9168 1.66669 15.0002 1.66669ZM7.50016 3.33335H9.16683V7.50002L8.33349 6.87502L7.50016 7.50002V3.33335ZM15.0002 16.6667H5.00016V3.33335H5.8335V10.8334L8.33349 8.95835L10.8335 10.8334V3.33335H15.0002V16.6667Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                  <div class="total-amount">
                    Rp. {{ formatMoney(dataDashboard.kas_bank) }}
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center mt-2"
                  >
                    <div class="date">{{ today }}</div>
                    <div class="icon-more" @click="toKasBank">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.83309 9.16666H12.1581L9.40809 6.42499C9.25117 6.26807 9.16302 6.05524 9.16302 5.83333C9.16302 5.61141 9.25117 5.39858 9.40809 5.24166C9.56501 5.08474 9.77784 4.99658 9.99976 4.99658C10.2217 4.99658 10.4345 5.08474 10.5914 5.24166L14.7581 9.40833C14.834 9.48758 14.8934 9.58103 14.9331 9.68333C15.0164 9.88621 15.0164 10.1138 14.9331 10.3167C14.8934 10.419 14.834 10.5124 14.7581 10.5917L10.5914 14.7583C10.514 14.8364 10.4218 14.8984 10.3202 14.9407C10.2187 14.983 10.1098 15.0048 9.99976 15.0048C9.88975 15.0048 9.78083 14.983 9.67928 14.9407C9.57773 14.8984 9.48556 14.8364 9.40809 14.7583C9.32999 14.6809 9.26799 14.5887 9.22568 14.4871C9.18338 14.3856 9.1616 14.2767 9.1616 14.1667C9.1616 14.0566 9.18338 13.9477 9.22568 13.8462C9.26799 13.7446 9.32999 13.6525 9.40809 13.575L12.1581 10.8333H5.83309C5.61208 10.8333 5.40012 10.7455 5.24384 10.5892C5.08756 10.433 4.99976 10.221 4.99976 9.99999C4.99976 9.77898 5.08756 9.56702 5.24384 9.41074C5.40012 9.25446 5.61208 9.16666 5.83309 9.16666Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-between mb-3">
                <div class="current-asset mb-3">
                  <div class="card">
                    <div class="card-body">
                      <div class="card-title">Aset saat ini</div>
                      <div class="row align-items-center">
                        <div class="col-md-4">
                          <asset-chart
                            :data="dataDashboard.aset.kategori"
                          ></asset-chart>
                        </div>
                        <div class="col-md-8" style="line-height: 45px">
                          <div class="card-title">Aset</div>
                          <div class="total-amount">
                            Rp. {{ formatMoney(dataDashboard.aset.nilai) }}
                          </div>
                          <div class="date">{{ today }}</div>
                        </div>
                      </div>
                      <div class="label mt-4">
                        <div class="row">
                          <div
                            class="col-3"
                            v-for="(value, index) in dataDashboard.aset.kategori
                              .label"
                            :key="index"
                          >
                            <div class="d-flex align-items-center">
                              <div
                                class="circle mr-0"
                                :style="{
                                  backgroundColor:
                                    dataDashboard.aset.kategori.color[index],
                                  width: '13px',
                                }"
                              ></div>
                              <div
                                style="
                                  line-height: 19px;
                                  display: -webkit-box;
                                  -webkit-line-clamp: 2;
                                  -webkit-box-orient: vertical;
                                  overflow: hidden;
                                  margin-left: 10px;
                                "
                              >
                                {{ value }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <svg
                    @click="toAsset"
                    width="31"
                    height="31"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon-row"
                  >
                    <path
                      d="M6.12497 9.62501H12.7662L9.87872 6.74626C9.71395 6.58149 9.62139 6.35802 9.62139 6.12501C9.62139 5.892 9.71395 5.66853 9.87872 5.50376C10.0435 5.33899 10.267 5.24643 10.5 5.24643C10.733 5.24643 10.9565 5.33899 11.1212 5.50376L15.4962 9.87876C15.5759 9.96198 15.6383 10.0601 15.68 10.1675C15.7675 10.3805 15.7675 10.6195 15.68 10.8325C15.6383 10.9399 15.5759 11.038 15.4962 11.1213L11.1212 15.4963C11.0399 15.5783 10.9431 15.6434 10.8365 15.6878C10.7298 15.7322 10.6155 15.7551 10.5 15.7551C10.3845 15.7551 10.2701 15.7322 10.1635 15.6878C10.0568 15.6434 9.96006 15.5783 9.87872 15.4963C9.79671 15.4149 9.73161 15.3181 9.68719 15.2115C9.64277 15.1049 9.61989 14.9905 9.61989 14.875C9.61989 14.7595 9.64277 14.6451 9.68719 14.5385C9.73161 14.4319 9.79671 14.3351 9.87872 14.2538L12.7662 11.375H6.12497C5.8929 11.375 5.67034 11.2828 5.50625 11.1187C5.34215 10.9546 5.24997 10.7321 5.24997 10.5C5.24997 10.2679 5.34215 10.0454 5.50625 9.88129C5.67034 9.7172 5.8929 9.62501 6.12497 9.62501Z"
                      fill="#263238"
                    />
                  </svg>
                </div>
                <div class="closeBook mb-3">
                  <div class="card">
                    <div class="card-body">
                      <div class="card-title" v-if="closeBookYear">
                        Proses Akhir Tahun
                      </div>
                      <div class="card-title" v-else>Proses Akhir Bulan</div>

                      <div class="form-group mt-3">
                        <label>Tanggal Pencatatan</label>
                        <Datepicker
                          v-model="tanggalCatat"
                          :format="format"
                          :autoApply="true"
                          :closeOnScroll="true"
                          placeholder="Pilih tanggal"
                          :enableTimePicker="false"
                          :maxDate="new Date()"
                          locale="id-ID"
                          selectText="Pilih"
                          cancelText="Batal"
                          :class="{
                            'is-invalid': formError && formError.tanggalCatat,
                          }"
                          @update:modelValue="checkDate"
                        />
                      </div>
                      <div class="form-group mt-3" v-if="closeBookYear">
                        <label>Periode </label>
                        <input
                          type="text"
                          disabled="true"
                          class="form-control"
                          :value="periodeClose"
                        />
                      </div>
                      <div class="form-group mt-3" v-else>
                        <label for="inputAddress2">Bulan </label>
                        <Datepicker
                          v-model="formBulan"
                          :format="formatMonth"
                          :autoApply="true"
                          placeholder="Pilih Bulan"
                          monthPicker
                          :allowedDates="allowDate"
                          :class="{
                            'is-invalid': formError && formError.bulan,
                          }"
                          @update:modelValue="checkMonth"
                        />
                        <div class="form-error" v-if="bulanError">
                          Bulan yang terpilih harus bulan sebelumnya
                        </div>
                      </div>
                      <div class="text-right">
                        <button
                          :disabled="bulanError || onCloseBook"
                          :style="{
                            opacity: bulanError || onCloseBook ? '0.5' : '1',
                          }"
                          type="button"
                          @click="setCloseBook"
                          class="btn btn-closeBook"
                        >
                          <span
                            class="fas fa-spin fa-spinner"
                            v-if="onCloseBook"
                          ></span>
                          Tutup Buku
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-9 mb-3">
              <div class="cash-flow">
                <div class="card">
                  <div class="card-body">
                    <div class="card-title">Cash Flow</div>
                    <chart-cash-flow
                      :data="dataDashboard.cash_flow"
                    ></chart-cash-flow>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-6">
                  <div class="cash-flow card-info">
                    <div class="card border-1">
                      <div class="card-body">
                        <div class="d-flex justify-content-between">
                          <div class="card-title">Client Invoices</div>
                          <div>
                            <div class="d-flex justify-content-between">
                              <div class="text">
                                {{ dataDashboard.client_invoice.current.total }}
                                Invoice diterbitkan
                              </div>
                              <div class="value">
                                Rp.
                                {{
                                  formatMoney(
                                    dataDashboard.client_invoice.current.nilai
                                  )
                                }}
                              </div>
                            </div>
                            <div class="d-flex justify-content-between">
                              <div class="text">
                                {{ dataDashboard.client_invoice.waiting.total }}
                                Invoice belum dibayar
                              </div>
                              <div class="value">
                                Rp.
                                {{
                                  formatMoney(
                                    dataDashboard.client_invoice.waiting.nilai
                                  )
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <ChartClientInvoice
                          :data="dataDashboard.client_invoice"
                        ></ChartClientInvoice>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="cash-flow card-info">
                    <div class="card border-2">
                      <div class="card-body">
                        <div class="d-flex justify-content-between">
                          <div class="card-title">Hutang Vendor</div>
                          <div>
                            <div class="d-flex justify-content-between">
                              <div class="text">
                                {{ dataDashboard.vendor_hutang.waiting.total }}
                                Tagihan menunggu
                              </div>
                              <div class="value">
                                Rp.
                                {{
                                  formatMoney(
                                    dataDashboard.vendor_hutang.waiting.nilai
                                  )
                                }}
                              </div>
                            </div>
                            <div class="d-flex justify-content-between">
                              <div class="text">
                                {{ dataDashboard.vendor_hutang.bayar.total }}
                                Tagihan harus dibayar
                              </div>
                              <div class="value">
                                Rp.
                                {{
                                  formatMoney(
                                    dataDashboard.vendor_hutang.bayar.nilai
                                  )
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <ChartHutang
                          :data="dataDashboard.vendor_hutang"
                        ></ChartHutang>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div
                  class="col-6"
                  v-for="(value, index) in dataDashboard.listKas"
                  :key="index"
                >
                  <div class="cash-flow card-info">
                    <div class="card border-3">
                      <div class="card-body">
                        <div class="d-flex justify-content-between">
                          <div class="card-title">{{ value.nama }}</div>
                          <div>
                            <div class="d-flex justify-content-between">
                              <div class="text">Saldo</div>
                              <div class="value">
                                Rp. {{ formatMoney(value.lastSaldo) }}
                              </div>
                            </div>
                            <div class="d-flex justify-content-between">
                              <div class="text">Mutasi terakhir</div>
                              <div class="value">
                                Rp. {{ formatMoney(value.lastMutasi) }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <ChartBank :data="value"></ChartBank>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-3 last-activity mb-3" style="padding-right: 40px">
              <div class="">
                <div class="card">
                  <div class="card-body">
                    <div class="card-title">Aktifitas Terakhir</div>

                    <div
                      class="mt-3"
                      v-for="(value, index) in dataDashboard.history"
                      :key="index"
                    >
                      <div class="card-text">{{ value.date }}</div>
                      <div
                        class="line"
                        :style="{ backgroundColor: value.background }"
                      ></div>
                      <ul
                        v-for="(item, index) in value.dataActivity"
                        :key="index"
                      >
                        <li>
                          <div class="item">
                            <div
                              class="circle"
                              :style="{ backgroundColor: value.background }"
                            ></div>
                            <div>{{ item.data }}</div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-9 mb-3 card-profit-loss">
              <div class="">
                <div class="card">
                  <div class="card-body">
                    <div class="card-title">
                      Laba/Rugi Tahun Ini (Semua Cabang)
                    </div>
                    <div class="total-profit-loss text-center">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.1421 4.00002L6.00913 16.1357C6.02271 16.2359 6.05113 16.3313 6.0921 16.4198C6.21543 16.6861 6.45246 16.889 6.74088 16.9661C6.82899 16.9898 6.92133 17.0016 7.01578 17H18C18.4416 17 18.8309 16.7103 18.9578 16.2874L21.9578 6.28736C22.0487 5.98459 21.991 5.65669 21.8023 5.40308C21.6136 5.14948 21.3161 5.00002 21 5.00002H6.31948L5.99058 2.86221C5.97826 2.77282 5.95413 2.68721 5.91981 2.607C5.85751 2.46092 5.76213 2.33439 5.64429 2.23521C5.53497 2.14302 5.40561 2.07384 5.26367 2.03514C5.17434 2.01068 5.0806 1.99842 4.9847 2.00002H3C2.44772 2.00002 2 2.44773 2 3.00002C2 3.5523 2.44772 4.00002 3 4.00002H4.1421ZM7.85794 15L6.62717 7.00002H19.656L17.256 15H7.85794Z"
                          fill="#2E3A59"
                        />
                        <path
                          d="M10 20C10 21.1046 9.10457 22 8 22C6.89543 22 6 21.1046 6 20C6 18.8954 6.89543 18 8 18C9.10457 18 10 18.8954 10 20Z"
                          fill="#2E3A59"
                        />
                        <path
                          d="M19 20C19 21.1046 18.1046 22 17 22C15.8954 22 15 21.1046 15 20C15 18.8954 15.8954 18 17 18C18.1046 18 19 18.8954 19 20Z"
                          fill="#2E3A59"
                        />
                      </svg>
                      IDR 986,351,388,000
                    </div>
                    <div class="d-flex justify-content-center mt-5">
                      <div class="progress">
                        <div class="barOverflow">
                          <div class="bar"></div>
                        </div>
                        <span class="d-none">100</span>
                        <div class="text">
                          <svg
                            width="14"
                            height="16"
                            viewBox="0 0 14 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M6.99992 0.666656C7.24304 0.666657 7.47619 0.763234 7.6481 0.935142L13.1481 6.43514C13.5061 6.79312 13.5061 7.37352 13.1481 7.73151C12.7901 8.08949 12.2097 8.08949 11.8517 7.73151L7.91659 3.79635L7.91659 14.4167C7.91659 14.9229 7.50618 15.3333 6.99992 15.3333C6.49366 15.3333 6.08325 14.9229 6.08325 14.4167L6.08325 3.79635L2.1481 7.7315C1.79012 8.08949 1.20972 8.08949 0.851737 7.7315C0.493757 7.37352 0.493757 6.79312 0.851737 6.43514L6.35174 0.935142C6.52365 0.763234 6.75681 0.666656 6.99992 0.666656Z"
                              fill="#3CB778"
                            />
                          </svg>

                          Dibanding Bulan Lalu
                        </div>
                      </div>

                      <div class="progress">
                        <div class="barOverflow">
                          <div class="bar"></div>
                        </div>
                        <span class="d-none">34</span>
                        <div class="text">
                          <svg
                            width="14"
                            height="16"
                            viewBox="0 0 14 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M7.00008 15.3333C6.75696 15.3333 6.52381 15.2368 6.3519 15.0649L0.851899 9.56486C0.493918 9.20688 0.493918 8.62647 0.851899 8.26849C1.20988 7.91051 1.79028 7.91051 2.14826 8.26849L6.08341 12.2036L6.08341 1.58334C6.08341 1.07708 6.49382 0.666676 7.00008 0.666676C7.50634 0.666676 7.91675 1.07708 7.91675 1.58334L7.91675 12.2036L11.8519 8.2685C12.2099 7.91051 12.7903 7.91051 13.1483 8.2685C13.5062 8.62648 13.5062 9.20688 13.1483 9.56486L7.64826 15.0649C7.47635 15.2368 7.24319 15.3333 7.00008 15.3333Z"
                              fill="#FF3D3D"
                            />
                          </svg>

                          Dibanding Bulan Lalu
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
          <!-- <div class="col-md-4">
            <button class="btn btn-warning">Clean Data Jurnal</button>
          </div> -->
        </div>
      </section>
    </div>
  </div>
  <div>
    <!-- <router-view></router-view> -->
  </div>
</template>
<script>
// import FooterDashboard from '../src/components/Footer.vue'
import { defineComponent } from "vue";
import SidebarDashboard from "../../../src/components/Sidebar.vue";
import NavbarDashboard from "../../../src/components/Navbar.vue";
import AssetChart from "./components/ChartAsset.vue";
import ChartCashFlow from "./components/ChartCashFlow.vue";
import ChartClientInvoice from "./components/ChartClientInvoice.vue";
import ChartBank from "./components/ChartBank.vue";
import ChartHutang from "./components/ChartHutang.vue";
import Datepicker from "vue3-date-time-picker";
import Select2 from "vue3-select2-component";
import $ from "jquery";
// import { DoughnutChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
import { get_Dashboard, post_CloseBook } from "../../actions/dashboard";
import moment from "moment";
import "moment/locale/id";
import randomColor from "random-color";
import { cksClient, showAlert } from "../../helper";
Chart.register(...registerables);

export default defineComponent({
  name: "DashboardLayout",
  components: {
    // FooterDashboard,

    SidebarDashboard,
    NavbarDashboard,
    AssetChart,
    ChartCashFlow,
    ChartClientInvoice,
    ChartBank,
    ChartHutang,
    // DoughnutChart,
    Datepicker,
    Select2,
    // Chart,
  },

  data() {
    return {
      percentage: 10,
      today: moment().format("DD MMMM YYYY"),
      formBulan: [],
      tanggalCatat: new Date(),
      bulanError: false,
      onCloseBook: false,
      disabledMonth: moment().format("YYYY-MMMM-DD HH:mm:ss"),
      closeBookYear: false,
      periodeClose: "",
      dataYear: cksClient().get('_PeriodYer') ? cksClient().get('_PeriodYer') : moment().format("YYYY"),
      optionYear: ["2022", "2023"],
      dataDashboard: {
        kegiatan: {
          bulan: [],
          nilai: [],
        },
        hutang: 0,
        piutang: 0,
        kas_bank: 0,
        aset: {
          kategori: {
            label: [],
            nilai: [],
            color: [],
          },
          nilai: 0,
        },
        cash_flow: {
          label: [],
          pemasukan: [],
          pengeluaran: [],
        },
        history: [],
        client_invoice: {
          current: {
            nilai: 0,
            total: 0,
          },
          waiting: {
            nilai: 0,
            total: 0,
          },
          label: [],
          list: [],
        },
        vendor_hutang: {
          bayar: {
            nilai: 0,
            total: 0,
          },
          waiting: {
            nilai: 0,
            total: 0,
          },
          label: [],
          list: [],
        },
        listKas: [],
      },
    };
  },

  mounted() {
    this.changeProgrss();
  },

  created() {
    this.availableYears();
    this.getData();
  },

  methods: {
    availableYears() {
      this.optionYear = [];
      var startYear = 2010;
      var endYear = parseInt(moment().format("YYYY"));
      for (let year = startYear; year <= endYear; year++) {
        this.optionYear.push(year);
      }
    },
    format(date){
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    },
    formatMonth(date) {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const monthText = month[date.month];
      const year = date.year;

      return `${monthText} ${year}`;
    },

    changeProgrss() {
      $(".progress").each(function () {
        var $bar = $(this).find(".bar");
        var $val = $(this).find("span");
        var perc = parseInt($val.text(), 10);

        $({
          p: 0,
        }).animate(
          {
            p: perc,
          },
          {
            duration: 3000,
            easing: "swing",
            step: function (p) {
              $bar.css({
                transform: "rotate(" + (45 + p * 1.8) + "deg)", // 100%=180° so: ° = % * 1.8
                // 45 is to add the needed rotation to have the green borders at the bottom
              });
              $val.text(p | 0);
            },
          }
        );
      });
    },
    selectYear(){
      cksClient().set("_PeriodYer", this.dataYear);
      this.getData();
    },
    getData() {
      this.$swal({
        title: "Tunggu Sebentar...",
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      this.$swal.showLoading();
      get_Dashboard(
        {
          id_company: cksClient().get("_account").id_company,
          id_karyawan: cksClient().get("_account").id,
          year: this.dataYear,
        },
        (res) => {
          this.$swal.close();
          var {
            today,
            hutang,
            piutang,
            kas_bank,
            aset,
            cash_flow,
            kegiatan,
            history,
            client_invoice,
            vendor_hutang,
            transaksi_kas,
          } = res.data;
          const { enableCloseBook, periodeCloseBook } = res;
          this.today = moment(today).format("DD MMMM YYYY");
          // console.log(res.data);
          this.dataDashboard.hutang = hutang;
          this.dataDashboard.piutang = piutang;
          this.dataDashboard.kas_bank = kas_bank;
          this.dataDashboard.cash_flow = cash_flow;
          this.dataDashboard.aset = aset;
          this.dataDashboard.client_invoice = client_invoice;
          this.dataDashboard.vendor_hutang = vendor_hutang;
          this.dataDashboard.listKas = transaksi_kas;
          this.closeBookYear =
            enableCloseBook && !cksClient().get("_account").akses_departement
              ? enableCloseBook
              : false;
          this.periodeClose = periodeCloseBook;
          this.bulanError = false;
          var kegiatanData = [];
          var historyData = [];
          // var i = 0;
          for (const key in kegiatan) {
            if (Object.hasOwnProperty.call(kegiatan, key)) {
              // i++;
              const element = kegiatan[key];
              var bulan = moment(element.tanggal_mulai).format("MMMM YYYY");
              if (!kegiatanData[bulan]) {
                kegiatanData[bulan] = [];
                kegiatanData[bulan].push({
                  tanggal: element.tanggal_mulai,
                  project: element.project,
                  kode: element.kode,
                });
              } else {
                kegiatanData[bulan].push({
                  tanggal: element.tanggal_mulai,
                  project: element.project,
                  kode: element.kode,
                });
              }
            }
          }
          this.dataDashboard.kegiatan.bulan = [];
          for (const key in kegiatanData) {
            if (Object.hasOwnProperty.call(kegiatanData, key)) {
              // const element = kegiatanData[key];
              this.dataDashboard.kegiatan.bulan.push(key);
            }
          }
          this.dataDashboard.kegiatan.nilai = kegiatanData;

          for (const key in history) {
            if (Object.hasOwnProperty.call(history, key)) {
              // i++;
              const element = history[key];
              var day = moment(element.created_at).format("DD MMMM YYYY");
              if (!historyData[day]) {
                historyData[day] = [];
                historyData[day].push({
                  data: element.action,
                  tanggal: element.created_at,
                });
              } else {
                historyData[day].push({
                  data: element.action,
                  tanggal: element.created_at,
                });
              }
            }
          }

          this.dataDashboard.history = [];
          for (const key in historyData) {
            if (Object.hasOwnProperty.call(historyData, key)) {
              const element = historyData[key];
              this.dataDashboard.history.push({
                date: key,
                background: randomColor().hexString(),
                dataActivity: element,
              });
              // this.dataDashboard.history.push(key);
            }
          }
          // this.dataDashboard.history.nilai = historyData;
        },
        (err) => {
          this.$swal.close();
          console.log(err);
        }
      );
    },

    formatMoney(a) {
      if (a) {
        return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
      return 0;
    },

    convertDate(string, format = "DD") {
      return moment(string).format(format);
    },

    toHutang() {
      this.$router.push({
        name: "DataAccountPayable",
      });
    },
    toPiutang() {
      this.$router.push({
        name: "DataCredit",
      });
    },
    toKasBank() {
      this.$router.push({
        name: "EstimatedAccount",
      });
    },
    toAsset() {
      this.$router.push({
        name: "DataAsset",
      });
    },
    checkMonth(val) {
      if (val.year && (val.month || val.month === 0)) {
        var now = moment().isAfter(
          moment(`${val.year}-${val.month + 1}-01`).format("YYYY-MM-DD"),
          "month"
        );
        this.bulanError = false;
        if (!now) {
          this.bulanError = true;
        }
      }
    },

    checkDate(data) {
      this.tanggalCatat = moment(data).format("YYYY-MM-DD");
    },

    setCloseBook() {
      if (!this.bulanError && this.tanggalCatat) {
        var bulan = null;
        if (this.closeBookYear) {
          bulan = {
            periode: "a",
            type: "tahunan",
            tanggal_catat: this.tanggalCatat
          };
        } else {
          bulan = {
            periode: moment(
              `${this.formBulan.year}-${this.formBulan.month + 1}-01`
            ).format("YYYY-MM"),
            type: "aset",
            tanggal_catat: this.tanggalCatat
          };
        }
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Sistem akan melakukan proses tutup buku secara otomatis?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan proses",
          onSubmit: () => {
            this.onCloseBook = true;
            post_CloseBook(
              bulan,
              () => {
                this.onCloseBook = false;
                showAlert(this.$swal, {
                  title: "BERHASIL!",
                  msg: "Proses tutup buku berhasil dilakukan",
                  onSubmit: () => {
                    location.reload();
                  },
                  onDismiss: () => {
                    location.reload();
                  },
                });
              },
              (err) => {
                console.log(err);
                this.onCloseBook = false;
                var msg = err.msg
                  ? err.msg
                  : "Terjadi kesalahan, silakan ulangi kembali nanti";
                showAlert(this.$swal, {
                  title: "GAGAL!",
                  msg: msg,
                  showCancelButton: true,
                  showConfirmButton: false,
                });
              }
            );
          },
        });
      }
    },
    // progressBar() {
    //   const a = document.getElementsByClassName("progress");
    //   a.each(function () {
    //     var bar = this.find(".bar");
    //     var val = this.find("span");
    //     var perc = parseInt(val.text(), 10);

    //     ({ p: 0 }.animate(
    //       { p: perc },
    //       {
    //         duration: 3000,
    //         easing: "swing",
    //         step: function (p) {
    //           bar.css({
    //             transform: "rotate(" + (45 + p * 1.8) + "deg)", // 100%=180° so: ° = % * 1.8
    //             // 45 is to add the needed rotation to have the green borders at the bottom
    //           });
    //           val.text(p | 0);
    //         },
    //       }
    //     ));
    //   });
    // },
  },
});
</script>

<style scoped src="../../assets/css/style.css"></style>

<style scoped src="../../assets/css/custom.css"></style>

<style scoped>
.card-info .text,
.card-info .value {
  font-size: 12px;
  margin-bottom: 7px;
}
.card-info .border-1 {
  border-left: 15px solid #5193ff;
}
.card-info .border-2 {
  border-left: 15px solid #6ec0fe;
}

.card-info .border-3 {
  border-left: 15px solid #d357ff;
}

.card-info .border-4 {
  border-left: 15px solid #69dadb;
}
.card-info .card-body {
  padding: 10px;
}

.card-info .text {
  width: 142px;
  font-weight: 500;
}
.card-info .value {
  color: #898989;
}

.card-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
}

.card .card-body {
  padding-bottom: 5px;
  padding-top: 12px;
}

.card-text {
  color: rgba(137, 137, 137, 1);
  font-size: 12px;
}

.card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
  padding: 10px;
  height: 100%;
}

.activity-date {
  background: linear-gradient(103.2deg, #6ec0fe 0%, #4df3dd 100%);
  border-radius: 15px;
  padding: 10px 20px;
  text-align: center;
  color: #fff;
  margin-right: 20px;
  width: 70px;
}

.activity-date .date {
  font-size: 17px;
  font-weight: bold;
}

.activity-date .day {
  font-size: 12px;
  font-weight: 400;
}

.activity-content {
  font-size: 12px;
  font-weight: 400;
}

.line {
  border-radius: 5px;
  height: 3px;
  width: 30px;
  margin-top: 10px;
}

.card-activity .line {
  background: linear-gradient(103.2deg, #6ec0fe 0%, #4df3dd 100%);
}

.content {
  margin-left: 17px;
  width: 100%;
  color: rgba(38, 50, 56, 1);
}

.card-activity {
  /* width: 30%;
  max-width: 40%; */
  /* max-height: 363px; */
}

.card-total-amount {
  width: 32%;
  color: #fff;
  line-height: 30px;
}

.card-total-amount .total-amount {
  font-weight: 600;
  font-size: 16px;
  border-radius: 10px;
}

.card-total-amount .date {
  font-size: 10px;
}

.card-total-amount .icon-detail {
  cursor: pointer;
}

.card-total-amount .card-title {
  font-size: 12px;
}

.gradation-1 {
  background: linear-gradient(90deg, #57c3ff 0%, #4e75ff 100%);
}

.gradation-2 {
  background: linear-gradient(90deg, #d357ff 0%, #9e51ff 100%);
}

.gradation-3 {
  background: linear-gradient(90deg, #7900ff 0%, #548cff 100%, #548cff 100%);
}

.current-asset {
  margin-top: 20px;
  width: 66%;
}

.last-activity.col-3 {
  width: 23%;
  flex: 0 0 23%;
  padding-left: 0px;
}

.last-activity .line {
  /* background: rgba(104, 202, 248, 1); */
}

.last-activity ul li {
  font-size: 13px;
  color: #263238;
}

.last-activity .circle {
  border-radius: 50%;
  height: 10px;
  width: 10px;
  position: absolute;
  left: 0;
  top: 9px;
}

.last-activity .item {
  position: relative;
  padding-left: 15px;
}

ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.closeBook {
  margin-top: 20px;
  width: 32%;
}

.col-9 {
  max-width: 77% !important;
  flex: 0 0 77%;
}

.current-asset .total-amount {
  font-size: 33px;
  font-weight: 600;
}

.btn-closeBook {
  background: #1eb7ff;
  color: #fff;
}

.label .circle {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-right: 15px;
}

.current-asset {
  position: relative;
}

.current-asset .date {
  font-size: 13px;
}

.current-asset .icon-row {
  position: absolute;
  bottom: 24px;
  right: 18px;
  cursor: pointer;
}

.label .circle:nth-child(1) {
  background: #69dadb;
}

.label .circle:nth-child(2) {
  background: #193498;
}

.label .circle:nth-child(3) {
  background: #69dadb;
}

.card-profit-loss {
  width: 100%;
}

.card-profit-loss .total-profit-loss {
  font-size: 20px;
  font-weight: 500;
  margin-top: 20px;
}

.progress {
  position: relative;
  margin: 4px;
  float: left;
  text-align: center;
  background-color: #fff;
  height: unset;
  line-height: 0px;
  margin-bottom: 6rem;
  overflow: unset;
}

.barOverflow {
  position: relative;
  overflow: hidden;
  width: 300px;
  height: 131px;
  margin-bottom: -14px;
}

.progress .text {
  position: absolute;
  top: 164px;
  left: 44%;
  transform: translate(-50%, 50%);
  color: #898989;
  font-size: 14px;
}

.bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 260px;
  height: 260px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 50px solid #eee;
  border-bottom-color: #0bf;
  border-right-color: #0bf;
}

.icon-more {
  cursor: pointer;
}
</style>