<template>
  <canvas :id="idChart" class="mt-3"></canvas>
</template>

<script>
import { Chart, registerables } from "chart.js";
import moment from "moment";
import "moment/locale/id";

Chart.register(...registerables);
export default {
  name: "ChartCashFlow",
  components: {},
  props: {
    data: Object,
  },
  data() {
    return {
      idChart: "",
      cashFlowChart: {
        chartType: "line",
        chartData: {
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "Mei",
            "Jun",
            "Jul",
            "Agu",
            "Sep",
            "Okt",
            "Nov",
            "Des",
          ],
          datasets: [
            {
              label: "Saldo Terakhir",
              data: [10, 25, 20, 25, 17, 20, 9, 10, 25, 20, 25, 17],
              backgroundColor: "rgba(110, 192, 254, 0.14)",
              borderColor: "rgba(110, 192, 254, 1)",
              lineTension: 0.3,
              pointBackgroundColor: "rgba(110, 192, 254, 1)",
              fill: true,
            },
          ],
        },
      },
      options: {
        plugins: {
          legend: {
            hidden: true,
            display: false,
          },
        },
      },
      canvasChart: "",
    };
  },

  created() {
    this.idChart = "chartId" + this.generateRandomString(5);
  },

  mounted() {
    this.setValueData(this.$props.data)
    // this.chartAsset();
  },

  methods: {
    generateRandomString(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    chartAsset() {
      const chartElement = document.getElementById(this.idChart);
      // const gradientBg = chartElement.createLinierGradient(0,0,0,400)
      // gradientBg.addColorStop(0, 'rgba(145, 69, 182, 0.14)')
      //  gradientBg.addColorStop(, 'rgba(145, 69, 182, 0.14)')
      this.canvasChart = new Chart(chartElement, {
        type: this.cashFlowChart.chartType,
        data: this.cashFlowChart.chartData,
        options: this.options,
      });
    },
    setValueData(newVal) {
      var label = [];
      for (const key in newVal.label) {
        if (Object.hasOwnProperty.call(newVal.label, key)) {
          const element = newVal.label[key];
          label.push(moment(element + "-01").format("MMM YYYY"));
        }
      }
      this.cashFlowChart.chartData.labels = label;
      this.cashFlowChart.chartData.datasets[0].data = newVal.value;
      if (this.canvasChart) {
        this.canvasChart.destroy();
      }
      this.chartAsset();
    }
  },
  watch: {
    data: function (newVal) {
      this.setValueData(newVal)
    },
  },
};
</script>
