<template>
  <div class="chart-container" style="position: relative">
    <canvas id="asset"></canvas>
  </div>
</template>

<script>
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
export default {
  name: "ChartAsset",
  components: {},
  props: {
    data: Object,
  },
  data() {
    return {
      assetChart: {
        chartType: "doughnut",
        chartData: {
          // labels: ["Red", "Blue", "Yellow", "aa"],
          datasets: [
            {
              label: [],
              data: [],
              backgroundColor: [],
              hoverOffset: 4,
            },
          ],
        },
        options: {
          labels: {
            display: false,
          },
          tooltips: {
            enabled: false,
          },
          scales: {
            xAxes: [{ display: false }],
            yAxes: [{ display: false }],
          },
        },
      },
      canvasChart: "",
    };
  },

  mounted() {
    this.chartAsset();
  },

  methods: {
    chartAsset() {
      const chartElement = document.getElementById("asset");
      this.canvasChart = new Chart(chartElement, {
        type: this.assetChart.chartType,
        data: this.assetChart.chartData,
      });
    },
  },
  watch: {
    data: function (newVal) {
      this.assetChart.chartData.datasets[0].label = newVal.label;
      this.assetChart.chartData.datasets[0].data = newVal.nilai;
      this.assetChart.chartData.datasets[0].backgroundColor = newVal.color;
      if (this.canvasChart) {
        this.canvasChart.destroy();
        // this.canvasChart.data.datasets[0].label = newVal.label;
        // this.canvasChart.data.datasets[0].data = newVal.nilai;
        // this.canvasChart.data.datasets[0].backgroundColor = newVal.color;
        // this.canvasChart.update();
      }
      this.chartAsset();
      // this.chartAsset();
    },
  },
};
</script>
