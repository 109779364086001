import { API } from "../../constans/Url"
import { apiClient } from "../../helper"

const get_Dashboard = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.DASHBOARD.GET, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_History = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.DASHBOARD.HISTORY, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_CloseBook = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.DASHBOARD.CLOSE_BOOK, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

export {
  get_Dashboard,
  post_CloseBook,
  get_History
}