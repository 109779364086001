<template>
  <canvas id="cash-hutang" class="mt-3"></canvas>
</template>

<script>
import { Chart, registerables } from "chart.js";
import moment from "moment";
import "moment/locale/id";

Chart.register(...registerables);
export default {
  name: "ChartCashFlow",
  components: {},
  props: {
    data: Object,
  },
  data() {
    return {
      cashFlowChart: {
        chartType: "bar",
        chartData: {
          labels: [
            "Due",
            "7-13 Mei",
            "14-20 Mei",
            "This Week",
            "28 Mei-3 Jun",
            "4 - 10 Jun",
          ],
          datasets: [
            {
              label: "Hutang Vendor",
              data: [10, 25, 20, 25, 17, 20],
              backgroundColor: "#9ADBFF",
              borderColor: "rgba(110, 192, 254, 1)",
              lineTension: 0.3,
              pointBackgroundColor: "rgba(110, 192, 254, 1)",
              fill: true,
            },
          ],
        },
      },
      options: {
        plugins: {
          legend: {
            hidden: true,
            display: false,
          },
        },
      },

      canvasChart: "",
    };
  },

  mounted() {
    // this.chartAsset();
  },

  methods: {
    chartAsset() {
      const chartElement = document.getElementById("cash-hutang");
      // const gradientBg = chartElement.createLinierGradient(0,0,0,400)
      // gradientBg.addColorStop(0, 'rgba(145, 69, 182, 0.14)')
      //  gradientBg.addColorStop(, 'rgba(145, 69, 182, 0.14)')
      this.canvasChart = new Chart(chartElement, {
        type: this.cashFlowChart.chartType,
        data: this.cashFlowChart.chartData,
        options: this.options,
      });
    },
  },
  watch: {
    data: function (newVal) {
      var label = [];
      for (const key in newVal.label) {
        if (Object.hasOwnProperty.call(newVal.label, key)) {
          const element = newVal.label[key];
          label.push(moment(element + "-01").format("MMM YYYY"));
        }
      }
      this.cashFlowChart.chartData.labels = label;
      this.cashFlowChart.chartData.datasets[0].data = newVal.list;
      if (this.canvasChart) {
        this.canvasChart.destroy();
      }
      this.chartAsset();
    },
  },
};
</script>